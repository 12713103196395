<template>
  <router-view />
</template>

<script>
export default {
  name: "Prizes",
  provide: {
    heading: { align: "center" },
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color: transparent !important
  opacity: 1 !important
  border: 0.17rem solid var(--v-primary-base)
  > span
    color: var(--v-primary-base)
</style>
